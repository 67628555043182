import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "./FirebaseConfig";
import { convertToFileName } from './MyFunctions';
import spinner from './loading.gif';

function CarouselImage(props) {
    const [imgURL, updateImgURL] = useState(spinner);

    useEffect(() => {
        getDownloadURL(ref(storage, props.path)).then((url) => {
            updateImgURL(url);
        });
    }, [props.path]);

    return (
        <img className='carousel-image' 
            id={props.selected ? 'selected' : null} 
            src={imgURL} 
            alt='testing' 
            onClick={() => props.updateCurImg(convertToFileName(props.path))} 
        />
    );
}

export { CarouselImage };
