// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDhWiSDQr2ms9Xep2KKMtypT2GX2QYhu4M",
    authDomain: "hlastalaphotography.firebaseapp.com",
    databaseURL: "https://hlastalaphotography.firebaseio.com",
    projectId: "hlastalaphotography",
    storageBucket: "hlastalaphotography.appspot.com",
    messagingSenderId: "968222689783",
    appId: "1:968222689783:web:a2ee345f1ddf3b1807c6f2",
    measurementId: "G-NZ9Q1XFT8Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const functions = getFunctions(app);

export { storage, functions };
