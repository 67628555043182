import { Link, useLocation } from 'react-router-dom';

function NavElements(props) {
    const currentLocation = useLocation().pathname;

    const toHome = '/';
    const toTechnical = '/technical';
    const toPhotography ='/photography';
    const toContact = '/contact';

    return (
        <nav className='nav-elements'>
            <Link className='nav-elem' id={currentLocation === toHome ? 'selected-nav-elem' : null} to={toHome}>Home</Link>
            <Link className='nav-elem' id={currentLocation.includes(toTechnical) ? 'selected-nav-elem' : null} to={toTechnical}>Technical</Link>
            <Link className='nav-elem' id={currentLocation.includes(toPhotography) ? 'selected-nav-elem' : null} to={toPhotography}>Photography</Link>
            <Link className='nav-elem' id={currentLocation.includes(toContact) ? 'selected-nav-elem' : null} to={toContact}>Contact</Link>
        </nav>
    );
}

export { NavElements };
