import { Link } from "react-router-dom";
import { SkillSubsection } from "./SkillSubsection";

function SkillSection(props) {
    return (
        <div id={props.rightSection ? 'right-section' : 'left-section'} className='skill-section'>
            <h2>{props.title}</h2>
            <SkillSubsection subtitle={props.subtitles[0]} items={props.items[0]} />
            <SkillSubsection subtitle={props.subtitles[1]} items={props.items[1]} />
            <SkillSubsection subtitle={props.subtitles[2]} items={props.items[2]} />
            <SkillSubsection subtitle={props.subtitles[3]} items={props.items[3]} />
            <Link to={props.title.toLowerCase()}>
                <button>{props.title === 'Technical' ? 'Recent Technical Projects' : 'View Photo Gallery'}</button>
            </Link>
        </div>
    );
}

export { SkillSection };