import { ReactComponent as Logo } from './logo.svg';
import { NavElements } from './NavElements';
import { FaBars, FaTimesCircle } from 'react-icons/fa';

function Header(props) {
    return (
        <header>
            <div id='desktop-header'>
                <Logo />
                <NavElements />
            </div>
            <div id='mobile-header'>
                <FaBars id='open-menu-icon' onClick={() => {
                    document.getElementById('mobile-menu').style.display = 'block';
                }} />
                <Logo />
                <div id='mobile-menu'>
                    <FaTimesCircle id='close-menu-icon' onClick={() => {
                        document.getElementById('mobile-menu').style.display = 'none';
                    }} />
                    <NavElements />
                </div>
            </div>
        </header>
    );
}

export { Header };
