function ShortBio(props) {
    return (
        <div id='short-bio'>
            <h4>Hello! My name is Jonah, and I am a Software Development Engineer in Test from Seattle, WA. I love photography, making web apps, and reviewing games. You will find my professional skills on this site, along with my technical projects and personal photography.</h4>
            <img src={require('./headshot.jpg')} alt='Jonah professional headshot' />
        </div>
    );
}

export { ShortBio };
