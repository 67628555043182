import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "./FirebaseConfig";
import spinner from './loading.gif';

function MainImage(props) {
    const [imgURL, updateImgURL] = useState(spinner);

    useEffect(() => {
        updateImgURL(spinner);
        getDownloadURL(ref(storage, props.path)).then((url) => {
            updateImgURL(url);
        });
    }, [props.path]);

    return (
        <div id='main-image'>
            <img src={imgURL} alt='testing' />
        </div>
    );
}

export { MainImage };
