import { Routes, Route } from 'react-router-dom';
import { HomePage } from './HomePage';
import { TechnicalPage } from './TechnicalPage';
import { PhotographyPage } from './PhotographyPage';
import { ContactPage } from './ContactPage';

function App(props) {
  return (
    <Routes>
      <Route exact path='/' element={<HomePage />} />
      <Route exact path='/technical' element={<TechnicalPage />} />
      <Route exact path='/photography' element={<PhotographyPage />} />
      <Route exact path='/photography/:imgName' element={<PhotographyPage />} />
      <Route exact path='/contact' element={<ContactPage />} />
    </Routes>
  );
}

export default App;
