function convertToMain(fileName) {
    return 'photography/gallery/' + fileName;
}

function convertToSquare(fileName) {
    return 'photography/square/' + fileName;
}

function convertToFileName(path) {
    if (path.includes('square')) {
        return path.substring(19);
    } else {
        return path.substring(20);
    }
}

export { convertToMain, convertToSquare, convertToFileName };