function TechnicalProject(props) {
    return (
        <div className='project'>
            <h2>{props.projectName}</h2>
            <div className='project-links'>
                <a className='first-link' target='_blank' href={props.projectLink} rel='noreferrer'>Project</a>
                <a href={props.repoLink} target='_blank' rel='noreferrer'>Repository</a>
            </div>
            <img src={require('./' + props.fileName + '.png')} alt={props.projectName + ' image'} />
        </div>
    );
}

export { TechnicalProject };
