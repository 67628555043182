import { httpsCallable } from 'firebase/functions';
import { functions } from './FirebaseConfig';
import { Header } from './Header';

function ContactPage(props) {
    return (
        <div id='contact-page'>
            <Header />
            <h1>Contact</h1>
            <form id='contact-form' onSubmit={(e) => {
                e.preventDefault();
                const submitForm = httpsCallable(functions, 'submitForm');
                submitForm({
                    name: document.getElementById('name').value,
                    email: document.getElementById('email').value,
                    phone: document.getElementById('phone-num').value,
                    message: document.getElementById('message').value
                }).then(() => {
                    document.getElementById('contact-form').reset();
                    window.alert("Submitted");
                })
            }}>
                <label htmlFor='name'>Name*:</label>
                <input type='text' id='name' required />

                <label htmlFor='email'>Email*:</label>
                <input type='email' id='email' required />

                <label htmlFor='phone-num'>Phone number:</label>
                <input type='number' id='phone-num' />

                <label>Inquiry:</label>
                <div id='inquiry-div'>
                    <input type='radio' name='inquiry' id='order' value='order' defaultChecked />
                    <label htmlFor='order' className='radio-label'>Order</label>

                    <input type='radio' name='inquiry' id='commission' value='commission' />
                    <label htmlFor='commission' className='radio-label'>Commission</label>

                    <input type='radio' name='inquiry' id='other' value='other' />
                    <label htmlFor='other' className='radio-label'>Other</label>
                </div>

                <label htmlFor='message'>Message*:</label>
                <textarea id='message'></textarea>

                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}

export { ContactPage };
