import { Header } from './Header';
import { ShortBio } from './ShortBio';
import { SkillSection } from './SkillSection';

const SkillsSection1Title = "Technical";
const SkillsSection1Subtitles = ["Languages", "Web Development", "Software Testing", "Conceptual"];
const SkillsSection1Items = [
    ["C#", "C", "Java", "JavaScript", "SQL", "Python"], 
    ["React", "CSS", "HTML", "jQuery", "PHP"], 
    ["Selenium", "Automated Testing", "Bug Reproduction"], 
    ["Algorithm Analysis", "Computer Architecture", "Object-Oriented Programming"]];

const SkillsSection2Title = "Photography";
const SkillsSection2Subtitles = ["Formats", "Software", "Printing", "Miscellaneous"];
const SkillsSection2Items = [
    ["DSLR", "Mirrorless Digital", "35mm Film", "4x5 Film"], 
    ["Bridge", "InDesign", "Lightroom", "Photoshop", "XD"], 
    ["Inkjet", "Digital Negatives", "Platinum Palladium", "Cyanotype", "B&W Darkroom"], 
    ["B&W Film Development", "Studio Lighting"]];

function HomePage(props) {
    return (
        <div id='home-page'>
            <Header />
            <ShortBio />
            <div id='skills'>
                <h1>Industry Skills</h1>
                <hr id='industry-skills-hr' />
                <div id='skills-sections'>
                    <SkillSection title={SkillsSection1Title} subtitles={SkillsSection1Subtitles} items={SkillsSection1Items} />
                    <hr id='skills-section-hr' />
                    <SkillSection rightSection={true} title={SkillsSection2Title} subtitles={SkillsSection2Subtitles} items={SkillsSection2Items} />
                </div>
            </div>
        </div>
    );
}

export { HomePage };
