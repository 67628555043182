import { Header } from "./Header";
import { TechnicalProject } from "./TechnicalProject";

function TechnicalPage(props) {
    return (
        <div id='technical-page'>
            <Header />
            <h1>Recent Projects</h1>
            <h4>Download my resume <a href='./Michael_Jonah_Hlastala.pdf' id='file-download' download>here</a></h4>
            <div id='projects'>
                <TechnicalProject projectName='My Rank Lists' projectLink='https://myranklists.com/' repoLink='https://github.com/MichaelJHla/ListMaker' fileName='list' />
                <TechnicalProject projectName='Siege Squad Stats' projectLink='https://siegesquadstats.com/' repoLink='https://github.com/MichaelJHla/SiegeSquadTracker' fileName='siege' />
                <TechnicalProject projectName='The Lazy Marine' projectLink='https://itch.io/jam/gmtk-2020/rate/695174' repoLink='https://github.com/MichaelJHla/GMTKGameJam2020' fileName='marine' />
                <TechnicalProject projectName='Shorack Portfolio' projectLink='https://kyolshorack.com/' repoLink='https://github.com/MichaelJHla/KyolPortfolio' fileName='shorack' />
                <TechnicalProject projectName='Rivernet' projectLink='https://yerc-rivernet.web.app/' repoLink='https://github.com/MichaelJHla/Rivernet' fileName='rivernet' />
            </div>
        </div>
    );
}

export { TechnicalPage };
