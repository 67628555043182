function SkillSubsection(props) {
    const skillsList = props.items.join(', ');

    return (
        <div className='section'>
            <h3>{props.subtitle}</h3>
            <p>{skillsList}</p>
        </div>
    );
}

export { SkillSubsection };