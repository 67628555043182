import { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { CarouselImage } from './CarouselImage';
import { Header } from './Header';
import { MainImage } from './MainImage';
import { storage } from './FirebaseConfig';
import { ref, listAll } from 'firebase/storage';
import $ from 'jquery';
import { convertToMain, convertToSquare, convertToFileName } from './MyFunctions';
import { useParams } from 'react-router-dom';

function PhotographyPage(props) {
    const [imgNames, updateImgNames] = useState([]);
    const [curImg, updateCurImg] = useState('');

    const { imgName } = useParams();

    useEffect(() => {
        $('#carousel').on('scroll', function() {
            let div = $('#carousel');
            if (div.scrollLeft() === 0) { //Scroll to beginning
                $('#left-button').css('color', 'white');
            } else if (div.scrollLeft() + div.outerWidth() >= div[0].scrollWidth - 1) { //Scroll to end
                $('#right-button').css('color', 'white');
            } else { //Scroll middle
                $('#left-button').css('color', '#A60303');
                $('#right-button').css('color', '#A60303');
            }
        });

        listAll(ref(storage, 'photography/square')).then((result) => {
            let paths = [];
            result.items.forEach((item) => {
                paths.push(convertToFileName(item['_location']['path']));
            });

            shuffle(paths); //OPTIONAL: I personally like how the images are shuffled, but ordering may be a good idea?
            if (imgName && paths.includes(imgName)) {
                updateCurImg(imgName);
                const index = paths.indexOf(imgName);
                paths.splice(index, 1);
                paths.unshift(imgName);
            } else {
                updateCurImg(paths[0]);
            }
            updateImgNames(paths);
        });
    }, [imgName]);

    useEffect(() => {
        window.history.replaceState(null, '', '/photography/' + curImg);
    });

    const images = imgNames.map(i => <CarouselImage selected={i === curImg} updateCurImg={updateCurImg} path={convertToSquare(i)} key={i} />);

    return (
        <div id='photography-page'>
            <Header />
            {curImg !== '' ? <MainImage path={convertToMain(curImg)} /> : null}
            <div id='carousel-and-buttons'>
                <FaAngleLeft id='left-button' className='carousel-button' onClick={() => {
                    const scrollPrev = $('#carousel').width() * 0.9;
                    $('#carousel').animate( {scrollLeft: '-=' + scrollPrev} , 500);
                }} />
                <div id='carousel'>
                    {images}
                </div>
                <FaAngleRight id='right-button' className='carousel-button' onClick={() => {
                    const scrollNext = $('#carousel').width() * 0.9;
                    $('#carousel').animate( {scrollLeft: '+=' + scrollNext} , 500);
                }} />
            </div>
        </div>
    );
}

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    // eslint-disable-next-line eqeqeq
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
}

export { PhotographyPage };
